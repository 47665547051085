<template>
	<div class="content-page sertificate-bg">
		<div class="card-box mt-3em container">
			<h4 class="mb-4 header-title text-center"><b>Mualiflik huquqi va turdosh huquqlar ob’yektlarini deponentlash xizmati</b></h4>
			<div class="responsive-table-plugin">
				<div class="table-rep-plugin">
					<div class="table-responsive" data-pattern="priority-columns">
						<table id="tech-companies-1" class="table table-striped mb-0 text-center">
							<thead class="table-dark">
								<tr>
									<th>№</th>
									<th>Ro‘yxatga olish raqami</th>
									<th>Asarning nomi</th>
									<th>Muallifi</th>
									<th>Huquq egasi</th>
									<th>Ro‘yxatga olish sanasi</th>
									<th>Sertifikat</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td></td>
									<td><input type="text" class="form-control" v-model="query.number" @keyup.enter="getData()" /></td>
									<td><input type="text" class="form-control" v-model="query.title" @keyup.enter="getData()" /></td>
									<td><input type="text" class="form-control" v-model="query.author" @keyup.enter="getData()" /></td>
									<td><input type="text" class="form-control" v-model="query.contributor" @keyup.enter="getData()" /></td>
									<td></td>
									<td></td>
								</tr>
								<tr v-for="(key, value) in list" :key="value">
									<td>{{ value + 1 }}</td>
									<td>{{ key.number }}</td>
									<td>{{ key.title }}</td>
									<td>{{ key.lastName }} {{ key.firstName }} {{ key.midName }}</td>
									<td>{{ key.contributor }}</td>
									<td>{{ parseDate(key.date) }}</td>
									<td>
										<span @click="downloadFile(key.certificateFilePath)" v-if="key.certificateFilePath != ''">
											<i class="ti-download"></i>
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {
	},
	data() {
		return {
			list: [],
			id: "",
			query: {
				number: "",
				title: "",
				author: "",
				contributor: "",
				givenDate: ""
			},
			clearQuery: {
				number: "",
				title: "",
				author: "",
				contributor: "",
				givenDate: ""
			},
		};
	},
	methods: {
		getData() {
			this.$api.get("/query_service/api/v1/get_certificates/get_certificate?" + 
				"number=" + this.query.number +
				"&title=" + this.query.title +
				"&author=" + this.query.author +
				"&contributor=" + this.query.contributor +
				"&offset=0&limit=10")
			.then(response => {
				this.query = Object.assign({}, this.clearQuery);
				this.list = response.result.data;
			},
			err => {
				this.$store.getters.errorParse(this, err);
			})
		},
		parseDate(date) {
			var parse = '0';
			if(date && date != 0){
				parse = moment(date).format('DD.MM.YYYY');
			}
			return parse;
		},
		downloadFile(fileUrl){
			var split = fileUrl.split("/");
			const link = document.createElement("a");
			link.href = this.$store.state.mainBaseUrl + fileUrl;
			link.setAttribute("download", split[2]);
			document.body.appendChild(link);
			link.click();
        }
	},
	mounted() {
		this.getData();
	},
	created() {
		this.query.number = this.$route.params.id;
		if (!this.query.number) {
			this.$router.push({ path: "/" });
		}
	}
}
</script>